/**************************************************************************************************
 * hoobs-core                                                                                     *
 * Copyright (C) 2020 HOOBS                                                                       *
 *                                                                                                *
 * This program is free software: you can redistribute it and/or modify                           *
 * it under the terms of the GNU General Public License as published by                           *
 * the Free Software Foundation, either version 3 of the License, or                              *
 * (at your option) any later version.                                                            *
 *                                                                                                *
 * This program is distributed in the hope that it will be useful,                                *
 * but WITHOUT ANY WARRANTY; without even the implied warranty of                                 *
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the                                  *
 * GNU General Public License for more details.                                                   *
 *                                                                                                *
 * You should have received a copy of the GNU General Public License                              *
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.                          *
 **************************************************************************************************/

export default {
    name: "hunters-green",
    title: "Hunter's Green",
    donate: "https://paypal.me/hoobsorg",
    homepage: {
        name: "HOOBS.org",
        url: "https://hoobs.org"
    },
    logo: {
        text: true,
        title: `
            <svg width="27" height="27" viewBox="0 0 80 80.92" xmlns="http://www.w3.org/2000/svg">
                <path fill="#bd8e38" d="M65.05,0H15A15.08,15.08,0,0,0,0,15.12V65.8A15.09,15.09,0,0,0,15,80.92h50.1A15.09,15.09,0,0,0,80,65.8V15.12A15.08,15.08,0,0,0,65.05,0Zm2,39.07a2.09,2.09,0,0,1-1.79,1.71,1.76,1.76,0,0,1-2.09-1c-.83-2.21-2.63-3.55-4.2-5.07a5.66,5.66,0,0,1-2-4.81c.09-1.24,0-2.49,0-3.73h0c0-1.66,0-3.32,0-5,0-1.5-.94-1.78-2.15-1.76s-2.64-.21-2.72,1.65c-.07,1.5,0,3-.06,4.51,0,.5.25,1.2-.23,1.44s-.83-.45-1.18-.78c-3.29-3.11-6.59-6.22-9.83-9.39-1-1-1.74-1-2.71,0Q27.51,27.79,16.75,38.57c-.94.94-1.07,1.7,0,2.57.24.19.44.44.66.65,1.41,1.4,1.62,1.36,3.13,0,4.68-4.35,9.08-9,13.63-13.48,1-.94,2-1.81,2.93-2.74a3.81,3.81,0,0,1,5.74,0c2,1.9,3.84,3.89,5.78,5.82C52.18,34.88,55.8,38.4,59.37,42c2.35,2.36,2.31,4.44,0,6.83-3.65,3.72-5.08,3.7-8.77,0-3.21-3.2-6.5-6.32-9.7-9.53-1-1-1.76-.91-2.71.06Q33,44.63,27.81,49.8c-.91.9-.91,1.65,0,2.48a13.05,13.05,0,0,1,1,1,1.48,1.48,0,0,0,2.48,0c1.82-1.83,3.7-3.59,5.55-5.38a4,4,0,0,1,5.9-.13q3.09,2.91,6.07,6a4,4,0,0,1,0,5.72c-1.36,1.47-2.82,2.87-4.23,4.3a8.46,8.46,0,0,0-2.22,2.92,1.84,1.84,0,0,1-2.1,1.23,2,2,0,0,1-1.84-1.63,2.09,2.09,0,0,1,1.09-2.5c2.93-1.4,4.61-4.19,6.94-6.26a1.08,1.08,0,0,0,0-1.75l-5.57-5.59c-.75-.75-1.43-.54-2.13.14-1.84,1.8-3.71,3.57-5.56,5.37a4,4,0,0,1-5.9.26c-.51-.43-1-.92-1.44-1.4-2.38-2.44-2.38-4.57,0-7Q31,42.31,36.22,37c2.22-2.23,4.32-2.25,6.57-.06l10.6,10.39c1.5,1.46,1.67,1.44,3.45-.37,1.51-1.54,1.53-1.76,0-3.25-5.21-5.21-10.46-10.39-15.65-15.62-.88-.89-1.58-.95-2.46-.07-2.41,2.4-4.84,4.77-7.27,7.16q-4.8,4.71-9.59,9.42a3.94,3.94,0,0,1-5.79.13c-3.81-3.46-4.75-5.29-.33-9.57,6.88-6.67,13.53-13.58,20.29-20.39,2.41-2.43,4.49-2.44,6.91-.07,1.65,1.62,3.35,3.2,5,4.81.68.66,1.15,1.07,1.51-.3a3.4,3.4,0,0,1,3-2.57,14.24,14.24,0,0,1,1.83-.21c4.28-.12,5.77,1.36,5.78,5.68,0,2.6,0,5.19,0,7.78a3.09,3.09,0,0,0,1.06,2.61c1.66,1.44,2.86,3.38,5,4.27A1.88,1.88,0,0,1,67.1,39.07Z" />
            </svg>
        `,
        login: `
            <svg width="27" height="27" viewBox="0 0 80 80.92" xmlns="http://www.w3.org/2000/svg">
                <rect fill="#bd8e38" x="1.25" y="1.25" width="77" height="78" rx="16.3" />
                <path fill="#474746" d="M17,44.62a3.78,3.78,0,0,0,5.56-.13q4.63-4.53,9.24-9.08c2.33-2.3,4.68-4.59,7-6.91.85-.84,1.52-.78,2.37.08,5,5,10,10,15.07,15.05,1.44,1.43,1.42,1.65,0,3.13-1.71,1.75-1.88,1.77-3.32.36q-5.1-5-10.21-10c-2.16-2.11-4.18-2.1-6.32,0q-5,5.07-10.06,10.17c-2.28,2.32-2.27,4.37,0,6.72.45.46.89.94,1.38,1.35a3.87,3.87,0,0,0,5.68-.25C35.16,53.42,37,51.71,38.73,50c.67-.66,1.33-.85,2.05-.13,1.79,1.79,3.57,3.59,5.36,5.39a1,1,0,0,1,0,1.69c-2.24,2-3.86,4.68-6.68,6a2,2,0,0,0-1,2.41,1.89,1.89,0,0,0,1.76,1.56,1.78,1.78,0,0,0,2-1.17,8,8,0,0,1,2.13-2.82c1.36-1.38,2.76-2.72,4.08-4.14a3.85,3.85,0,0,0,0-5.52c-1.92-2-3.86-3.86-5.85-5.74a3.8,3.8,0,0,0-5.67.13c-1.78,1.73-3.59,3.43-5.34,5.18-.86.86-1.59.87-2.39,0-.29-.33-.61-.65-.93-1-.87-.81-.87-1.53,0-2.39q5-5,10-10.07c.91-.93,1.64-1,2.61-.06,3.08,3.09,6.25,6.1,9.34,9.19,3.55,3.54,4.92,3.55,8.44,0,2.26-2.3,2.29-4.31,0-6.58-3.44-3.44-6.92-6.84-10.36-10.27-1.87-1.86-3.68-3.78-5.56-5.61a3.66,3.66,0,0,0-5.53,0c-.93.89-1.91,1.74-2.82,2.64-4.38,4.33-8.62,8.8-13.12,13-1.45,1.34-1.66,1.38-3,0-.21-.21-.4-.44-.63-.63-1-.83-.88-1.56,0-2.47Q28,28.25,38.26,17.78a1.55,1.55,0,0,1,2.61,0c3.12,3,6.29,6,9.46,9.06.33.31.6,1,1.13.75s.22-.91.23-1.39c0-1.45,0-2.9.05-4.35.08-1.79,1.43-1.56,2.62-1.59s2.07.24,2.07,1.69c0,1.6,0,3.2,0,4.8h0c0,1.2.08,2.4,0,3.6A5.48,5.48,0,0,0,58.3,35c1.51,1.46,3.25,2.75,4.05,4.89a1.69,1.69,0,0,0,2,.91,2,2,0,0,0,1.72-1.65,1.81,1.81,0,0,0-.92-2.18c-2.07-.86-3.23-2.73-4.82-4.11a3,3,0,0,1-1-2.52c0-2.5,0-5,0-7.5,0-4.17-1.45-5.59-5.56-5.48a13,13,0,0,0-1.76.21A3.29,3.29,0,0,0,49.14,20c-.35,1.32-.8.93-1.45.3-1.6-1.56-3.23-3.08-4.82-4.64-2.33-2.28-4.34-2.28-6.66.06C29.71,22.3,23.31,29,16.69,35.39,12.44,39.52,13.34,41.28,17,44.62Z" />
            </svg>
        `,
        about: `
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="177px" height="37.9px" viewBox="0 0 375 80">
                <path fill="#bd8e38" d="M362.57,15.15h5v1.49H366v4.51h-1.82V16.64h-1.57Zm5.56,0h2l1.42,3.19L373,15.15h2v6h-1.63V17.77l-1.18,2.79H371l-1.18-2.79v3.38h-1.64Z" />
                <path fill="#bd8e38" d="M65.05,0H15A15,15,0,0,0,0,15v50.1A15,15,0,0,0,15,80h50.1a15,15,0,0,0,15-15V15A15,15,0,0,0,65.05,0Z" />
                <path fill="#bd8e38" d="M144.23,15.83V64.17H133.07V44.35H111.16V64.17H100V15.83h11.16V34.89h21.91V15.83ZM152.64,40c0-14.36,11.16-25,26.39-25s26.38,10.57,26.38,25S194.18,65,179,65,152.64,54.37,152.64,40Zm41.48,0c0-9.18-6.48-15.47-15.09-15.47S163.94,30.82,163.94,40,170.42,55.47,179,55.47,194.12,49.19,194.12,40Zm16.67,0c0-14.36,11.16-25,26.39-25s26.39,10.57,26.39,25-11.23,25-26.39,25S210.79,54.37,210.79,40Zm41.48,0c0-9.18-6.48-15.47-15.09-15.47S222.09,30.82,222.09,40s6.48,15.47,15.09,15.47S252.27,49.19,252.27,40Zm63.94,11c0,8.36-6.62,13.19-19.3,13.19H272V15.83h23.57c12,0,18.25,5,18.25,12.57A11.62,11.62,0,0,1,307.39,39C312.76,40.76,316.21,44.9,316.21,51ZM283.07,24.25v11.4h11.09c5.44,0,8.4-1.93,8.4-5.73s-3-5.67-8.4-5.67ZM305,49.81c0-4.08-3.11-6-8.89-6h-13v12h13C301.87,55.75,305,54,305,49.81Zm15.91,9.6L324.68,51a28.34,28.34,0,0,0,15.92,5c6.61,0,9.3-2.21,9.3-5.18,0-9-28-2.83-28-20.78,0-8.22,6.61-15.06,20.32-15.06,6.06,0,12.26,1.45,16.74,4.28l-3.44,8.5A27.17,27.17,0,0,0,342.11,24C335.5,24,333,26.46,333,29.5c0,8.91,28,2.77,28,20.51,0,8.08-6.68,15-20.46,15C332.88,65,325.16,62.72,320.89,59.41Z" />
                <path fill="#fff" d="M16.67,43.94a3.93,3.93,0,0,0,5.72-.13l9.49-9.22c2.39-2.33,4.8-4.65,7.18-7,.87-.85,1.56-.79,2.44.08C46.63,32.78,51.82,37.84,57,42.93c1.47,1.46,1.45,1.68,0,3.18-1.76,1.77-1.93,1.79-3.41.36L43.05,36.32c-2.23-2.14-4.3-2.13-6.5,0q-5.17,5.15-10.33,10.32c-2.35,2.34-2.34,4.43,0,6.81.46.47.92,1,1.42,1.37a4,4,0,0,0,5.84-.25c1.82-1.76,3.68-3.49,5.49-5.25.69-.67,1.37-.87,2.11-.14L46.6,54.7a1,1,0,0,1,0,1.71c-2.3,2-4,4.75-6.87,6.12A2,2,0,0,0,38.66,65a2,2,0,0,0,1.82,1.59,1.82,1.82,0,0,0,2.08-1.2,8.22,8.22,0,0,1,2.19-2.85c1.4-1.41,2.84-2.76,4.19-4.21a3.86,3.86,0,0,0,0-5.59c-2-2-4-3.92-6-5.82a4,4,0,0,0-5.84.13c-1.83,1.75-3.68,3.47-5.48,5.26a1.46,1.46,0,0,1-2.45,0,12.68,12.68,0,0,0-1-1c-.88-.82-.88-1.55,0-2.43q5.16-5.06,10.22-10.2c.94-.95,1.68-1,2.68-.07,3.17,3.14,6.42,6.19,9.6,9.32,3.64,3.59,5.06,3.61,8.67,0,2.32-2.33,2.36-4.37,0-6.67-3.53-3.49-7.1-6.94-10.65-10.42C46.88,28.9,45,27,43.08,25.1a3.77,3.77,0,0,0-5.67,0c-1,.9-2,1.76-2.91,2.68C30,32.2,25.65,36.73,21,41c-1.48,1.37-1.7,1.41-3.09,0-.22-.21-.41-.45-.65-.64-1-.85-.91-1.59,0-2.5Q27.94,27.34,38.5,16.72a1.61,1.61,0,0,1,2.68,0c3.21,3.1,6.47,6.14,9.72,9.19.34.32.62,1,1.16.76s.23-.92.24-1.41c0-1.47,0-2.94.05-4.41.09-1.81,1.47-1.58,2.69-1.61s2.13.25,2.13,1.72c0,1.62,0,3.24,0,4.86h0c0,1.22.08,2.44,0,3.65a5.54,5.54,0,0,0,1.93,4.71c1.56,1.48,3.34,2.79,4.16,5a1.74,1.74,0,0,0,2.07.92,2,2,0,0,0,1.76-1.67,1.82,1.82,0,0,0-.94-2.21c-2.13-.87-3.32-2.77-5-4.17a3.05,3.05,0,0,1-1-2.55c0-2.54,0-5.07,0-7.61,0-4.23-1.49-5.67-5.72-5.56a15.93,15.93,0,0,0-1.81.21A3.36,3.36,0,0,0,49.68,19c-.36,1.34-.82.94-1.48.3-1.65-1.58-3.33-3.12-5-4.71-2.39-2.31-4.46-2.3-6.84.07C29.72,21.3,23.14,28.06,16.34,34.58,12,38.76,12.9,40.55,16.67,43.94Z" />
            </svg>
        `,
        certified: `
            <svg width="20" height="20" viewBox="0 0 80 80.92" xmlns="http://www.w3.org/2000/svg">
                <rect fill="#bd8e38" x="1.25" y="1.25" width="77" height="78" rx="16.3" />
                <path fill="#474746" d="M17,44.62a3.78,3.78,0,0,0,5.56-.13q4.63-4.53,9.24-9.08c2.33-2.3,4.68-4.59,7-6.91.85-.84,1.52-.78,2.37.08,5,5,10,10,15.07,15.05,1.44,1.43,1.42,1.65,0,3.13-1.71,1.75-1.88,1.77-3.32.36q-5.1-5-10.21-10c-2.16-2.11-4.18-2.1-6.32,0q-5,5.07-10.06,10.17c-2.28,2.32-2.27,4.37,0,6.72.45.46.89.94,1.38,1.35a3.87,3.87,0,0,0,5.68-.25C35.16,53.42,37,51.71,38.73,50c.67-.66,1.33-.85,2.05-.13,1.79,1.79,3.57,3.59,5.36,5.39a1,1,0,0,1,0,1.69c-2.24,2-3.86,4.68-6.68,6a2,2,0,0,0-1,2.41,1.89,1.89,0,0,0,1.76,1.56,1.78,1.78,0,0,0,2-1.17,8,8,0,0,1,2.13-2.82c1.36-1.38,2.76-2.72,4.08-4.14a3.85,3.85,0,0,0,0-5.52c-1.92-2-3.86-3.86-5.85-5.74a3.8,3.8,0,0,0-5.67.13c-1.78,1.73-3.59,3.43-5.34,5.18-.86.86-1.59.87-2.39,0-.29-.33-.61-.65-.93-1-.87-.81-.87-1.53,0-2.39q5-5,10-10.07c.91-.93,1.64-1,2.61-.06,3.08,3.09,6.25,6.1,9.34,9.19,3.55,3.54,4.92,3.55,8.44,0,2.26-2.3,2.29-4.31,0-6.58-3.44-3.44-6.92-6.84-10.36-10.27-1.87-1.86-3.68-3.78-5.56-5.61a3.66,3.66,0,0,0-5.53,0c-.93.89-1.91,1.74-2.82,2.64-4.38,4.33-8.62,8.8-13.12,13-1.45,1.34-1.66,1.38-3,0-.21-.21-.4-.44-.63-.63-1-.83-.88-1.56,0-2.47Q28,28.25,38.26,17.78a1.55,1.55,0,0,1,2.61,0c3.12,3,6.29,6,9.46,9.06.33.31.6,1,1.13.75s.22-.91.23-1.39c0-1.45,0-2.9.05-4.35.08-1.79,1.43-1.56,2.62-1.59s2.07.24,2.07,1.69c0,1.6,0,3.2,0,4.8h0c0,1.2.08,2.4,0,3.6A5.48,5.48,0,0,0,58.3,35c1.51,1.46,3.25,2.75,4.05,4.89a1.69,1.69,0,0,0,2,.91,2,2,0,0,0,1.72-1.65,1.81,1.81,0,0,0-.92-2.18c-2.07-.86-3.23-2.73-4.82-4.11a3,3,0,0,1-1-2.52c0-2.5,0-5,0-7.5,0-4.17-1.45-5.59-5.56-5.48a13,13,0,0,0-1.76.21A3.29,3.29,0,0,0,49.14,20c-.35,1.32-.8.93-1.45.3-1.6-1.56-3.23-3.08-4.82-4.64-2.33-2.28-4.34-2.28-6.66.06C29.71,22.3,23.31,29,16.69,35.39,12.44,39.52,13.34,41.28,17,44.62Z" />
            </svg>
        `,
        loader: `
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="467px" height="100px" viewBox="0 0 375 80">
                <path fill="#fff" d="M362.57,15.15h5v1.49H366v4.51h-1.82V16.64h-1.57Zm5.56,0h2l1.42,3.19L373,15.15h2v6h-1.63V17.77l-1.18,2.79H371l-1.18-2.79v3.38h-1.64Z" />
                <path fill="#fff" d="M65.05,0H15A15,15,0,0,0,0,15v50.1A15,15,0,0,0,15,80h50.1a15,15,0,0,0,15-15V15A15,15,0,0,0,65.05,0Z" />
                <path fill="#fff" d="M144.23,15.83V64.17H133.07V44.35H111.16V64.17H100V15.83h11.16V34.89h21.91V15.83ZM152.64,40c0-14.36,11.16-25,26.39-25s26.38,10.57,26.38,25S194.18,65,179,65,152.64,54.37,152.64,40Zm41.48,0c0-9.18-6.48-15.47-15.09-15.47S163.94,30.82,163.94,40,170.42,55.47,179,55.47,194.12,49.19,194.12,40Zm16.67,0c0-14.36,11.16-25,26.39-25s26.39,10.57,26.39,25-11.23,25-26.39,25S210.79,54.37,210.79,40Zm41.48,0c0-9.18-6.48-15.47-15.09-15.47S222.09,30.82,222.09,40s6.48,15.47,15.09,15.47S252.27,49.19,252.27,40Zm63.94,11c0,8.36-6.62,13.19-19.3,13.19H272V15.83h23.57c12,0,18.25,5,18.25,12.57A11.62,11.62,0,0,1,307.39,39C312.76,40.76,316.21,44.9,316.21,51ZM283.07,24.25v11.4h11.09c5.44,0,8.4-1.93,8.4-5.73s-3-5.67-8.4-5.67ZM305,49.81c0-4.08-3.11-6-8.89-6h-13v12h13C301.87,55.75,305,54,305,49.81Zm15.91,9.6L324.68,51a28.34,28.34,0,0,0,15.92,5c6.61,0,9.3-2.21,9.3-5.18,0-9-28-2.83-28-20.78,0-8.22,6.61-15.06,20.32-15.06,6.06,0,12.26,1.45,16.74,4.28l-3.44,8.5A27.17,27.17,0,0,0,342.11,24C335.5,24,333,26.46,333,29.5c0,8.91,28,2.77,28,20.51,0,8.08-6.68,15-20.46,15C332.88,65,325.16,62.72,320.89,59.41Z" />
                <path fill="#232b21" d="M16.67,43.94a3.93,3.93,0,0,0,5.72-.13l9.49-9.22c2.39-2.33,4.8-4.65,7.18-7,.87-.85,1.56-.79,2.44.08C46.63,32.78,51.82,37.84,57,42.93c1.47,1.46,1.45,1.68,0,3.18-1.76,1.77-1.93,1.79-3.41.36L43.05,36.32c-2.23-2.14-4.3-2.13-6.5,0q-5.17,5.15-10.33,10.32c-2.35,2.34-2.34,4.43,0,6.81.46.47.92,1,1.42,1.37a4,4,0,0,0,5.84-.25c1.82-1.76,3.68-3.49,5.49-5.25.69-.67,1.37-.87,2.11-.14L46.6,54.7a1,1,0,0,1,0,1.71c-2.3,2-4,4.75-6.87,6.12A2,2,0,0,0,38.66,65a2,2,0,0,0,1.82,1.59,1.82,1.82,0,0,0,2.08-1.2,8.22,8.22,0,0,1,2.19-2.85c1.4-1.41,2.84-2.76,4.19-4.21a3.86,3.86,0,0,0,0-5.59c-2-2-4-3.92-6-5.82a4,4,0,0,0-5.84.13c-1.83,1.75-3.68,3.47-5.48,5.26a1.46,1.46,0,0,1-2.45,0,12.68,12.68,0,0,0-1-1c-.88-.82-.88-1.55,0-2.43q5.16-5.06,10.22-10.2c.94-.95,1.68-1,2.68-.07,3.17,3.14,6.42,6.19,9.6,9.32,3.64,3.59,5.06,3.61,8.67,0,2.32-2.33,2.36-4.37,0-6.67-3.53-3.49-7.1-6.94-10.65-10.42C46.88,28.9,45,27,43.08,25.1a3.77,3.77,0,0,0-5.67,0c-1,.9-2,1.76-2.91,2.68C30,32.2,25.65,36.73,21,41c-1.48,1.37-1.7,1.41-3.09,0-.22-.21-.41-.45-.65-.64-1-.85-.91-1.59,0-2.5Q27.94,27.34,38.5,16.72a1.61,1.61,0,0,1,2.68,0c3.21,3.1,6.47,6.14,9.72,9.19.34.32.62,1,1.16.76s.23-.92.24-1.41c0-1.47,0-2.94.05-4.41.09-1.81,1.47-1.58,2.69-1.61s2.13.25,2.13,1.72c0,1.62,0,3.24,0,4.86h0c0,1.22.08,2.44,0,3.65a5.54,5.54,0,0,0,1.93,4.71c1.56,1.48,3.34,2.79,4.16,5a1.74,1.74,0,0,0,2.07.92,2,2,0,0,0,1.76-1.67,1.82,1.82,0,0,0-.94-2.21c-2.13-.87-3.32-2.77-5-4.17a3.05,3.05,0,0,1-1-2.55c0-2.54,0-5.07,0-7.61,0-4.23-1.49-5.67-5.72-5.56a15.93,15.93,0,0,0-1.81.21A3.36,3.36,0,0,0,49.68,19c-.36,1.34-.82.94-1.48.3-1.65-1.58-3.33-3.12-5-4.71-2.39-2.31-4.46-2.3-6.84.07C29.72,21.3,23.14,28.06,16.34,34.58,12,38.76,12.9,40.55,16.67,43.94Z" />
            </svg>
        `
    },
    loader: {
        foreground: "#bd8e38",
        background: "#232b21"
    },
    charts: {
        foreground: "#999",
        running: "#019420",
        stopped: "#940101",
        cpu: "#417a36",
        memory: "#8f610d",
        uptime: "#999"
    },
    qr: {
        foreground: "#ffffff00",
        background: "#bd8e38"
    },
    terminal: {
        foreground: "#a1b29d",
        background: "#2a3926"
    },
    accessories: {
        off: "#586355",
        thermostat: "#586355",
        garage: "#bd8e38",
        lock: "#bd8e38",
        light: "#dbd21d",
        disposal: "#2ea1b8",
        fireplace: "#cf9a17",
        switch: "#bd8e38",
        fan: "#bd8e38",
        outlet: "#bd8e38"
    }
};
