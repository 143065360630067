/**************************************************************************************************
 * hoobs-core                                                                                     *
 * Copyright (C) 2020 HOOBS                                                                       *
 *                                                                                                *
 * This program is free software: you can redistribute it and/or modify                           *
 * it under the terms of the GNU General Public License as published by                           *
 * the Free Software Foundation, either version 3 of the License, or                              *
 * (at your option) any later version.                                                            *
 *                                                                                                *
 * This program is distributed in the hope that it will be useful,                                *
 * but WITHOUT ANY WARRANTY; without even the implied warranty of                                 *
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the                                  *
 * GNU General Public License for more details.                                                   *
 *                                                                                                *
 * You should have received a copy of the GNU General Public License                              *
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.                          *
 **************************************************************************************************/

export default {
    "af": "af", // Afrikaans
    "sq": "sq", // Albanian
    "ar": "ar", // Arabic (Standard)
    "ar-dz": "ar", // Arabic (Algeria)
    "ar-bh": "ar", // Arabic (Bahrain)
    "ar-eg": "ar", // Arabic (Egypt)
    "ar-iq": "ar", // Arabic (Iraq)
    "ar-jo": "ar", // Arabic (Jordan)
    "ar-kw": "ar", // Arabic (Kuwait)
    "ar-lb": "ar", // Arabic (Lebanon)
    "ar-ly": "ar", // Arabic (Libya)
    "ar-ma": "ar", // Arabic (Morocco)
    "ar-om": "ar", // Arabic (Oman)
    "ar-qa": "ar", // Arabic (Qatar)
    "ar-sa": "ar", // Arabic (Saudi Arabia)
    "ar-sy": "ar", // Arabic (Syria)
    "ar-tn": "ar", // Arabic (Tunisia)
    "ar-ae": "ar", // Arabic (U.A.E.)
    "ar-ye": "ar", // Arabic (Yemen)
    "ar": "ar", // "Aragonese
    "hy": "hy", // "Armenian
    "as": "as", // "Assamese
    "ast": "at", // "Asturian
    "az": "az", // "Azerbaijani
    "eu": "eu", // "Basque
    "bg": "bg", // "Bulgarian
    "be": "be", // "Belarusian
    "bn": "bn", // "Bengali
    "bs": "bs", // "Bosnian
    "br": "br", // "Breton
    "bg": "bg", // "Bulgarian
    "my": "my", // "Burmese
    "ca": "ca", // "Catalan
    "ch": "ch", // "Chamorro
    "ce": "ce", // "Chechen
    "zh": "zh", // "Chinese
    "zh-hk": "zh", // "Chinese (Hong Kong)
    "zh-cn": "zh", // "Chinese (PRC)
    "zh-sg": "zh", // "Chinese (Singapore)
    "zh-tw": "zh", // "Chinese (Taiwan)
    "cv": "cv", // Chuvash
    "co": "co", // Corsican
    "cr": "cr", // Cree
    "hr": "hr", // Croatian
    "cs": "cs", // Czech
    "da": "da", // Danish
    "nl": "nl", // Dutch (Standard)
    "nl-be": "nl", // Dutch (Belgian)
    "en": "en", // English
    "en-au": "en", // English (Australia)
    "en-bz": "en", // English (Belize)
    "en-ca": "en", // English (Canada)
    "en-ie": "en", // English (Ireland)
    "en-jm": "en", // English (Jamaica)
    "en-nz": "en", // English (New Zealand)
    "en-ph": "en", // English (Philippines)
    "en-za": "en", // English (South Africa)
    "en-tt": "en", // English (Trinidad & Tobago)
    "en-gb": "en", // English (United Kingdom)
    "en-us": "en", // English (United States)
    "en-zw": "en", // English (Zimbabwe)
    "eo": "eo", // Esperanto
    "et": "et", // Estonian
    "fo": "fo", // Faeroese
    "fa": "fa", // Farsi
    "fj": "fj", // Fijian
    "fi": "fi", // Finnish
    "fr": "fr", // French (Standard)
    "fr-be": "fr", // French (Belgium)
    "fr-ca": "fr", // French (Canada)
    "fr-fr": "fr", // French (France)
    "fr-lu": "fr", // French (Luxembourg)
    "fr-mc": "fr", // French (Monaco)
    "fr-ch": "fr", // French (Switzerland)
    "fy": "fy", // Frisian
    "fur": "fu", // Friulian
    "gd": "gd", // Gaelic (Scots)
    "gd-ie": "gd", // Gaelic (Irish)
    "gl": "gl", // Galacian
    "ka": "ka", // Georgian
    "de": "de", // German (Standard)
    "de-at": "de", // German (Austria)
    "de-de": "de", // German (Germany)
    "de-li": "de", // German (Liechtenstein)
    "de-lu": "de", // German (Luxembourg)
    "de-ch": "de", // German (Switzerland)
    "el": "el", // Greek
    "gu": "gu", // Gujurati
    "ht": "ht", // Haitian
    "he": "he", // Hebrew
    "hi": "hi", // Hindi
    "hu": "hu", // Hungarian
    "is": "is", // Icelandic
    "id": "id", // Indonesian
    "iu": "iu", // Inuktitut
    "ga": "ga", // Irish
    "it": "it", // Italian (Standard)
    "it-ch": "it", // Italian (Switzerland)
    "ja": "ja", // Japanese
    "kn": "kn", // Kannada
    "ks": "ks", // Kashmiri
    "kk": "kk", // Kazakh
    "km": "km", // Khmer
    "ky": "ky", // Kirghiz
    "tlh": "tl", // Klingon
    "ko": "ko", // Korean
    "ko-kp": "ko", // Korean (North Korea)
    "ko-kr": "ko", // Korean (South Korea)
    "la": "la", // Latin
    "lv": "lv", // Latvian
    "lt": "lt", // Lithuanian
    "lb": "lb", // Luxembourgish
    "mk": "mk", // FYRO Macedonian
    "ms": "ms", // Malay
    "ml": "ml", // Malayalam
    "mt": "mt", // Maltese
    "mi": "mi", // Maori
    "mr": "mr", // Marathi
    "mo": "mo", // Moldavian
    "nv": "nv", // Navajo
    "ng": "ng", // Ndonga
    "ne": "ne", // Nepali
    "no": "no", // Norwegian
    "nb": "nb", // Norwegian (Bokmal)
    "nn": "nn", // Norwegian (Nynorsk)
    "oc": "oc", // Occitan
    "or": "or", // Oriya
    "om": "om", // Oromo
    "fa": "fa", // Persian
    "fa-ir": "fa", // Persian/Iran
    "pl": "pl", // Polish
    "pt": "pt", // Portuguese
    "pt-br": "pt", // Portuguese (Brazil)
    "pa": "pa", // Punjabi
    "pa-in": "pa", // Punjabi (India)
    "pa-pk": "pa", // Punjabi (Pakistan)
    "qu": "qu", // Quechua
    "rm": "rm", // Rhaeto-Romanic
    "ro": "ro", // Romanian
    "ro-mo": "ro", // Romanian (Moldavia)
    "ru": "ru", // Russian
    "ru-mo": "ru", // Russian (Moldavia)
    "sz": "sz", // Sami (Lappish)
    "sg": "sg", // Sango
    "sa": "sa", // Sanskrit
    "sc": "sc", // Sardinian
    "gd": "gd", // Scots Gaelic
    "sd": "sd", // Sindhi
    "si": "si", // Singhalese
    "sr": "sr", // Serbian
    "sk": "sk", // Slovak
    "sl": "sl", // Slovenian
    "so": "so", // Somani
    "sb": "sb", // Sorbian
    "es": "es", // Spanish
    "es-ar": "es", // Spanish (Argentina)
    "es-bo": "es", // Spanish (Bolivia)
    "es-cl": "es", // Spanish (Chile)
    "es-co": "es", // Spanish (Colombia)
    "es-cr": "es", // Spanish (Costa Rica)
    "es-do": "es", // Spanish (Dominican Republic)
    "es-ec": "es", // Spanish (Ecuador)
    "es-sv": "es", // Spanish (El Salvador)
    "es-gt": "es", // Spanish (Guatemala)
    "es-hn": "es", // Spanish (Honduras)
    "es-mx": "es", // Spanish (Mexico)
    "es-ni": "es", // Spanish (Nicaragua)
    "es-pa": "es", // Spanish (Panama)
    "es-py": "es", // Spanish (Paraguay)
    "es-pe": "es", // Spanish (Peru)
    "es-pr": "es", // Spanish (Puerto Rico)
    "es-es": "es", // Spanish (Spain)
    "es-uy": "es", // Spanish (Uruguay)
    "es-ve": "es", // Spanish (Venezuela)
    "sx": "sx", // Sutu
    "sw": "sw", // Swahili
    "sv": "sv", // Swedish
    "sv-fi": "sv", // Swedish (Finland)
    "sv-sv": "sv", // Swedish (Sweden)
    "ta": "ta", // Tamil
    "tt": "tt", // Tatar
    "te": "te", // Teluga
    "th": "th", // Thai
    "tig": "ti", // Tigre
    "ts": "ts", // Tsonga
    "tn": "tn", // Tswana
    "tr": "tr", // Turkish
    "tk": "tk", // Turkmen
    "uk": "uk", // Ukrainian
    "hsb": "hs", // Upper Sorbian
    "ur": "ur", // Urdu
    "ve": "ve", // Venda
    "vi": "vi", // Vietnamese
    "vo": "vo", // Volapuk
    "wa": "wa", // Walloon
    "cy": "cy", // Welsh
    "xh": "xh", // Xhosa
    "ji": "ji", // Yiddish
    "zu": "zu" // Zulu
}
